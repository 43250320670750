<script setup lang="ts">
import type { SubHome } from '@design-system/components/Header/HeaderMonoGram.props'
import { cards } from '@design-system/mocks/productTile'
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'

const nuxt = useNuxtApp()
const router = useRouter()
const localePath = useLocalePath()
const { isHome } = useRouteHelper()
const { currentWebsite, deferredCurrentWebsite, isGlobalWebsite } = useWebsite()
const { deferredBrandTheme } = await useTheme()
const { top: snackbarTop } = useSnackbarPosition()
const { isOpen: isConfirmCountryOpen } = useConfirmCountry()

// if (isConfirmCountryOpen.value) {
//   useStyleTag(`
//      :root {
//        --confirm-country-height: 132px;
//        @screen md {
//          --confirm-country-height: 80px;
//        }
//      }
//      }
//    `)
//   try {
//     document.documentElement.style.setProperty(
//       '--confirm-country-height',
//       `132px`
//     )
//   } catch (error) {
//     console.error(error)
//   }
// }

const topHeader = ref<HTMLElement>()
const { height } = useElementSize(topHeader)
const { menuStatus } = useMenu()
const { lastAddedToCart } = useAddToCart()
const { topHeaderHeight: topHeaderHeightState } = useHeaderAndNavbarHeight()
const { internalBrandSortedList } = await useBrandsSettings()

const { isDialogOpen: isDialogOpenMiniCart } = useDialog(
  SIDEBAR_IDS.headerSideCart
)
const { isDialogOpen: isDialogOpenLogin } = useDialog(SIDEBAR_IDS.loginSidebar)

const { isDialogOpen: isAccountDialogOpen } = useDialog(
  SIDEBAR_IDS.cartContactUsSideSlide
)

const isRegisterSuccesfull = computed(() =>
  router.currentRoute.value.path.includes('register-successful')
)

const shouldTopBarBeClosed = computed(
  () =>
    (menuStatus?.value?.isActive ||
      isDialogOpenMiniCart.value ||
      isDialogOpenLogin.value ||
      isAccountDialogOpen.value) &&
    !isHome.value
)

const { data: items } = await useMarketingStrip(
  `${currentWebsite.value}-marketing-strips`
)

const messages = computed(() => items.value?.content?.messages ?? [])

const subHome: SubHome[] = internalBrandSortedList.value
  .filter(brand => brand.name !== 'Global' && brand.monogram)
  .map(brand => {
    return {
      to: localePath(`/${convertStringToSlug(brand.name)}/experience`),
      icon: convertStringToSlug(brand.name) as LogoType,
    }
  })

watch(
  height,
  (val, oldVal) => {
    if (val !== oldVal) {
      topHeaderHeightState.value = val
    }
  },
  { immediate: true }
)
const main = ref<HTMLElement>()

const deferredIsHome = ref(isHome.value)
nuxt.hook('page:finish', () => {
  deferredCurrentWebsite.value = currentWebsite.value
  deferredIsHome.value = isHome.value
})
</script>

<template>
  <div
    ref="main"
    class="duration-600 transition-all"
    :class="[
      `theme-${deferredBrandTheme} `,
      {
        'mb-8': isHome,
      },
    ]"
  >
    <WishlistNotifications
      class="positive-padding gap-y-md fixed left-0 right-0 z-[9999] flex flex-col"
      :style="`top:${snackbarTop}`"
    />
    <div
      class="z-50 contents"
      :class="{
        'relative top-0 !block lg:sticky': isHome,
      }"
    >
      <HeaderStripWrapper :is-hidden="shouldTopBarBeClosed">
        <ConfirmCountry />
        <HeaderMonoGram :is-hidden="isGlobalWebsite" :sub-home="subHome" />
        <MarketingStrip
          :messages="messages"
          :class="{ 'order-1': !isGlobalWebsite }"
        />
      </HeaderStripWrapper>

      <HeaderWrapperLogic>
        <template #header="props">
          <!-- HP -->
          <HeaderContainer
            v-if="deferredIsHome"
            class="z-40 w-full"
            :class="[
              isConfirmCountryOpen
                ? 'top-[168px] md:top-[116px]'
                : 'top-[36px]',
            ]"
            :menu-lists="props.menuLists ?? []"
            :page-has-confirm-country="isConfirmCountryOpen"
          />
          <!-- DEFAULT -->
          <HeaderDefault
            v-else
            v-bind="{
              ...props,
              freezeAnimation: shouldTopBarBeClosed || props.freezeAnimation,
              staticOnMobile: !!isRegisterSuccesfull,
            }"
          />
        </template>
        <template #navigation="props">
          <HeaderNavigationBar v-bind="props" />
        </template>
      </HeaderWrapperLogic>
    </div>

    <main id="main" class="h-full w-full">
      <slot />
    </main>

    <FooterMegalogo v-if="!isGlobalWebsite" />

    <CartFeedbackAddToCart :is-already-added="lastAddedToCart?.isAlreadyAdded">
      <template v-if="lastAddedToCart" #product>
        <UIProductCardHorizontal v-bind="lastAddedToCart" />
      </template>
      <template v-if="lastAddedToCart" #relatedProducts>
        <div class="grid grid-cols-12">
          <UIProductTile
            v-for="(card, index) in cards"
            :key="index"
            v-bind="card"
            :price="{
              originalPrice: lastAddedToCart.info.price,
            }"
            class="col-span-6"
          />
        </div>
      </template>
    </CartFeedbackAddToCart>
    <div class="mb-[var(--navbar-height)] lg:mb-0">
      <FooterMain />
    </div>
    <GlobalSlides />
    <GlobalSnackbars />
  </div>
</template>
